import React, { useContext } from "react";
import styled from "styled-components";
import { H4, P, Card, ButtonPrimary, FlexBox } from "notes";
import { SurveyContext } from "Providers";
import Api from "modules/Api";
import { Link } from "Components";
import formatSongs from "modules/formatSongs";
import { Song } from "./components";
import { FormattedMessage } from "react-intl";

export const ConnectedSongDisplay = props => {
  const {
    state: {
      collector,
      instance,
      collector: {
        setlist: { albums, max_songs }
      }
    },
    songs,
    setSongs,
    shows,
    setStep,
    saveResponses
  } = useContext(SurveyContext);

  const songlist = albums.reduce((accum, album) => {
    const albumSongs = album.songs.map(song => ({
      ...song,
      album
    }));
    return accum.concat(albumSongs);
  }, []);

  const selectedSongs = songlist
    .filter(song => songs.includes(song.id))
    .sort(
      ({ id: idA }, { id: idB }) => songs.indexOf(idA) - songs.indexOf(idB)
    );

  const submitSetlist = () => {
    let answers = [{ questionId: "songs", value: formatSongs(songs, albums) }];
    if (shows && shows.length > 0) {
      answers.push({ questionId: "shows", value: shows });
    }
    saveResponses(answers)
      .then(({ data, errors }) => {
        setStep("survey");
        // handle error
      })
      .catch(err => {
        // handle error
      });
  };

  const handleRemove = id => {
    setSongs(songs.filter(songId => songId !== id));
  };

  // There will also be an option in the survey object
  // specifying if concert selection is required or not
  const showsRequired = /*concert selection required && */ shows.length > 0;

  return (
    <div>
      <StyledCard>
        <H4Bold>
          <FormattedMessage
            id="label.selected_songs"
            defaultMessage="Selected Songs"
          />
        </H4Bold>
        {songs.length ? (
          <>
            <SongContainer>
              {selectedSongs.map(({ id, name }) => (
                <Song name={name} handleRemove={() => handleRemove(id)} />
              ))}
            </SongContainer>
            <SelectedSongCountContainer>
              {max_songs ? (
                max_songs > 1 ? (
                  <FormattedMessage
                    id="label.song_count_max_multiple"
                    values={{
                      selected_count: songs.length,
                      max_songs: max_songs,
                      multiple: (songs.length !== 1 || max_songs) && "s"
                    }}
                    defaultMessage={`${songs.length} of ${max_songs} Songs Selected`}
                  />
                ) : (
                  <FormattedMessage
                    id="label.song_count_max_single"
                    values={{
                      selected_count: songs.length,
                      max_songs: max_songs,
                      multiple: (songs.length !== 1 || max_songs) && "s"
                    }}
                    defaultMessage={`${songs.length} of 1 Song Selected`}
                  />
                )
              ) : songs.length > 1 ? (
                <FormattedMessage
                  id="label.song_count_multiple"
                  values={{
                    selected_count: songs.length
                  }}
                  defaultMessage={`${songs.length} Songs Selected`}
                />
              ) : (
                <FormattedMessage
                  id="label.song_count_single"
                  values={{
                    selected_count: songs.length
                  }}
                  defaultMessage={`${songs.length} Song Selected`}
                />
              )}
            </SelectedSongCountContainer>
            {max_songs && songs.length > max_songs && (
              <P>
                <FormattedMessage
                  id="label.max_songs_exceeded"
                  defaultMessage="Select no more than {max_songs} songs"
                  values={{
                    max_songs
                  }}
                />
              </P>
            )}
          </>
        ) : (
          <StringMargin>
            <FormattedMessage
              id="message.no_songs_selected"
              defaultMessage="You have not selected any songs yet..."
            />
          </StringMargin>
        )}
        <Link to="survey">
          <ButtonPrimary
            style={{ marginTop: "24px" }}
            disabled={
              !songs.length ||
              (showsRequired && !shows.length) ||
              (max_songs && max_songs < songs.length)
            }
            onClick={() => submitSetlist()}
          >
            <FormattedMessage id="label.continue" defaultMessage="Continue" />
          </ButtonPrimary>
        </Link>
      </StyledCard>
    </div>
  );
};

const SelectedSongCountContainer = styled(P)`
  font-size: 16px;
`;
const StyledCard = styled(Card)`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  padding-bottom: 40px;
  position: sticky;
  top: 24px;
  width: 288px;
`;

const StringMargin = styled(P)`
  margin-top: 40px;
  text-align: center;
  margin-bottom: 50px;
`;

const SongContainer = styled(FlexBox)`
  margin-top: 24px;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 24px;
`;

const H4Bold = styled(H4)`
  font-weight: 600;
`;

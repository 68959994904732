import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Small, FlexBox, P } from "notes";
import { Logo } from "../";
import { FormattedMessage } from "react-intl";
import { SurveyContext } from "Providers";
import { LanguageSelector } from "Components";
import _ from "lodash";

export const Footer = () => {
  const {
    state: {
      collector: {
        setlist: { customizations },
        artist
      },
      locale
    },
    getCustomization
  } = useContext(SurveyContext);

  const ourPrivacy = () => (
    <StyledLink to="/privacy" data-javelin-name={`our-privacy`}>
      <SpecialLink>
        <FormattedMessage id="label.privacy" defaultMessage="Privacy Policy" />
      </SpecialLink>
    </StyledLink>
  );

  const ourTerms = () => (
    <StyledLink to="/terms" data-javelin-name={`our-terms`}>
      <SpecialLink>
        <FormattedMessage
          id="label.terms"
          defaultMessage="Terms &amp; Conditions"
        />
      </SpecialLink>
    </StyledLink>
  );

  const theirTerms = () => {
    const url = getCustomization("termsUrl");
    if (!url || url === "") return null;
    return (
      <ExternalLink href={url} data-javelin-name={`their-terms`}>
        <FormattedMessage
          id="label.artist_terms"
          values={{
            artist: artist.name
          }}
        />
      </ExternalLink>
    );
  };

  const theirPrivacy = () => {
    const url = getCustomization("privacyUrl");
    if (!url || url === "") return null;
    return (
      <ExternalLink href={url} data-javelin-name={`their-privacy`}>
        <FormattedMessage
          id="label.artist_privacy"
          values={{
            artist: artist.name
          }}
          defaultMessage={`${artist.name}'s Privacy Policy`}
        />
      </ExternalLink>
    );
  };

  const buildLinks = () => {
    let links = [];
    // Always use STS Privacy Policy
    links.push(ourPrivacy());
    let theirs = theirPrivacy();
    if (theirs) {
      links.push(theirs);
    }
    // Always use STS Terms & Conditions
    links.push(ourTerms());
    theirs = theirTerms();
    if (theirs) {
      links.push(theirs);
    }
    return links;
  };

  const additionalLinks = () => {
    const { footerLinks } = customizations;
    if (!footerLinks || footerLinks.length < 1) return null;
    const links = [];
    footerLinks.forEach((additional, index) => {
      try {
        const { text, link } = additional[locale || "en"];
        if (text && text !== "" && link && link !== "") {
          links.push(
            <ExternalLink
              href={link}
              data-javelin-name={`additional-link-${index + 1}`}
            >
              {text}
            </ExternalLink>
          );
        }
      } catch (err) {}
    });
    return links;
  };

  return (
    <FooterContainer>
      <Logo footer />
      <Copyright>&copy; Set The Set, 2020</Copyright>
      <LinkContainer>
        {buildLinks()}
        {additionalLinks()}
      </LinkContainer>
      <LanguageSelectorContainer>
        <LanguageSelector />
      </LanguageSelectorContainer>
    </FooterContainer>
  );
};

const LanguageSelectorContainer = styled.div`
  align-items: center;
  background-color: ${props =>
    props.theme.dark ? props.theme.colors.text : "#FAFAFA"};
  bottom: 0;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  width: 100%;
  padding-bottom: 20px;
  @media only screen and ${props => props.theme.media.large} {
    background-color: #ffffff;
  }
`;

const StyledLink = styled(Link)`
  &,
  &:visited {
    color: ${props =>
      props.theme.dark ? props.theme.darkDefault : props.theme.lightDefault};
    @media only screen and ${props => props.theme.media.large} {
      color: ${props => props.theme.lightDefault};
    }
  }
`;

const SpecialLink = styled(P)`
  font-size: 12px;
  margin: 0 8px;
`;

const ExternalLink = styled(P).attrs(() => ({ as: "a" }))`
  font-size: 12px;
  margin: 0 8px;
  &,
  &:visited {
    color: ${props =>
      props.theme.dark ? props.theme.darkDefault : props.theme.lightDefault};
    @media only screen and ${props => props.theme.media.large} {
      color: ${props => props.theme.lightDefault};
    }
  }
`;

const FooterContainer = styled(FlexBox)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 2px solid
    ${props =>
      props.dark
        ? props.theme.darkFooterBorder
        : props.theme.lightFooterBorder};
  padding-top: 7px;
  padding-bottom: 40px;
  @media only screen and ${props => props.theme.media.large} {
    border-top-color: ${props => props.theme.lightFooterBorder};
  }
`;
const Copyright = styled(Small)`
  color: ${props =>
    props.theme.dark ? props.theme.darkDefault : props.theme.lightDefault};
  margin: 16px 0 8px 0;
  @media only screen and ${props => props.theme.media.large} {
    color: ${props => props.theme.lightDefault};
  }
`;
const LinkContainer = styled.div`
  display: flex;
  text-align: center;
`;

import React, { useContext, useEffect } from "react";
import _ from "lodash";
import { SurveyContext } from "../../../Providers/SurveyProvider";
import Page from "./Page";
import { Redirect } from "react-router-dom";
import Api from "modules/Api";

const ThankYou = () => {
  const {
    state: { collector, survey, instance },
    updateInstance
  } = useContext(SurveyContext);

  useEffect(() => {
    if (instance && instance.status !== 10) {
      updateInstance();
    }
  }, []);
  /**
   * First, check if there is a specific 'Thank You' page.
   * If not, see if there is custom HTML.
   * If not, show a default.
   */
  const pages = _.get(survey, "Page", []);
  const page = _.find(pages, ({ name }) => name === "thankyou");
  return (
    <>
      <Redirect from="/survey" to="/survey-complete" />
      {page ? (
        <Page thankYouPage={true} data={page} />
      ) : (
        <div>
          Thanks for voting! Your choices will be taken into account when making
          the setlists for this tour. In the meantime, turn your dream setlist
          in your own playlist on Spotify below!
        </div>
      )}
    </>
  );
};

export default ThankYou;

import React, { useContext, Fragment } from "react";
import { SurveyContext } from "Providers/SurveyProvider";
import styled from "styled-components";

const LanguageSelector = () => {
  const {
    state: { locale },
    setLanguage
  } = useContext(SurveyContext);

  const render = () => {
    return (
      <Fragment>
        {locale === "en" ? (
          <Selected>English</Selected>
        ) : (
          <Unselected
            onClick={() => setLanguage("en")}
            data-javelin-name={`change-language`}
            data-javelin-meta={`en`}
          >
            English
          </Unselected>
        )}
        |
        {locale === "es" ? (
          <Selected>Español</Selected>
        ) : (
          <Unselected
            onClick={() => setLanguage("es")}
            data-javelin-name={`change-language`}
            data-javelin-meta={`es`}
          >
            Español
          </Unselected>
        )}
      </Fragment>
    );
  };

  return render();
};

export default LanguageSelector;

const Selected = styled.a`
  color: ${props => (props.theme.dark ? "#FFFFFF" : props.theme.colors.text)};
  cursor: default;
  ${props => props.theme.fonts.default};
  font-size: 12px;
  line-height: 18px;
  padding: 4px 8px;
  @media only screen and ${props => props.theme.media.large} {
    color: ${props => props.theme.colors.text};
  }
`;

const Unselected = styled(Selected)`
  color: ${props => props.theme.colors.disabledText};
  cursor: pointer;
  @media only screen and ${props => props.theme.media.large} {
    color: ${props => props.theme.colors.disabledText};
  }
`;

import React from "react";
import { H2, withDefaultMedia } from "notes";
import {
  DesktopSongs,
  MobileLanding,
  MobileShows,
  MobileSongs
} from "./Landing";
import { Switch, Route, Redirect } from "react-router-dom";
import { SurveyConsumer } from "Providers";
import { Survey } from "./Survey";
import { Privacy, Terms } from "./Legal";
import { MobileProgress, SpotifyAuth, FacebookAuth } from "Components";

const RoutingInternal = ({ matches, ...props }) => (
  <SurveyConsumer>
    {({
      shows,
      songs,
      step,
      state: {
        collector: {
          setlist: { concerts }
        }
      }
    }) => {
      // Also need to check if concert selection is required
      const surveyOk =
        true ||
        ((!concerts || !concerts.length || (concerts && !!shows.length)) &&
          shows.length &&
          !!songs.length);

      // Desktop
      return (
        <>
          <Route path="/auth/spotify" exact component={SpotifyAuth} />
          {matches.large ? (
            <Switch>
              <Route path="/auth/fb" exact component={FacebookAuth} />
              <Redirect from="/shows" to="/" />
              <Redirect from="/songs" to="/" />
              <Route path="/privacy" exact component={Privacy} />
              <Route path="/terms" exact component={Terms} />
              <Route path="/" exact component={DesktopSongs} />
              {surveyOk ? (
                <Route
                  path="/:path(survey|survey-complete)"
                  exact
                  component={Survey}
                />
              ) : (
                [
                  <Redirect from="/survey" to="/" />,
                  <Redirect from="/survey-complete" to="/" />
                ]
              )}
              {/*<Redirect from="/:route" to="/" />*/}
            </Switch>
          ) : (
            <>
              <Switch>
                <Route path="/auth/fb" exact component={FacebookAuth} />
                <Route exact path="/" component={MobileLanding} />
                <Route path="/privacy" exact component={Privacy} />
                <Route path="/terms" exact component={Terms} />
                <Route exact path="/shows" component={MobileShows} />
                {(!concerts || concerts.length < 1 || !!shows.length) && (
                  <Route exact path="/songs" component={MobileSongs} />
                )}
                {surveyOk ? (
                  <Route
                    path="/:path(survey|survey-complete)"
                    exact
                    component={Survey}
                  />
                ) : (
                  [
                    <Redirect from="/survey" to="/" />,
                    <Redirect from="/survey-complete" to="/" />
                  ]
                )}
                <Redirect from="/:route" to="/" />
              </Switch>
              <Route
                exact
                path="/:path(shows|songs)"
                component={MobileProgress}
              />
            </>
          )}
        </>
      );
    }}
  </SurveyConsumer>
);

export const Routing = withDefaultMedia(RoutingInternal);

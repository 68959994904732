const dev = {
  endpoints: {
    survey: {
      url: "/api/survey"
    },
    venue: {
      url: "/api/venue/:term"
    },
    artist: {
      url: "/api/artist/:term",
      image: "https://d3c1e40eohvx2d.cloudfront.net/:hash.jpeg"
    },
    response: {
      url: "/api/response"
    },
    geoIp: {
      url: "/api/geoip/"
    },
    instance: {
      url: "/api/instance/"
    },
    location: {
      url: "/api/location/:term"
    }
  }
};

const production = {
  endpoints: {
    survey: {
      url: "https://btrak3on4l.execute-api.us-west-2.amazonaws.com/api"
    },
    venue: {
      url:
        "https://survey.musicaudience.info/search?q=(:term*)+OR+(:term)&fq=type:'venue'&q.parser=lucene&sort=venue+desc"
    },
    artist: {
      url:
        "https://survey.musicaudience.info/search?q=(:term*)+OR+(:term)&fq=type:'artist'&q.parser=lucene",
      image: "https://d3c1e40eohvx2d.cloudfront.net/:hash.jpeg"
    },
    response: {
      url: "https://opcedtke2b.execute-api.us-west-2.amazonaws.com/api/"
    },
    geoIp: {
      url: "https://j3zxic9sd3.execute-api.us-west-2.amazonaws.com/prod/geoip/-"
    },
    instance: {
      url: "https://bmp5vklfu6.execute-api.us-west-2.amazonaws.com/api"
    },
    location: {
      url:
        "https://admin.musicaudienceexchange.com/api/v1/ame/autocomplete?type=location&location_type=city&q=:term"
    }
  }
};

const config = process.env.REACT_APP_STAGE === "production" ? production : dev;

export const endpoints = config.endpoints;

export default {
  ...config
};

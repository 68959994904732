import _ from "lodash";
import config from "Routes/Survey/config";
import AlphaId from "./AlphaId";

const handleResponse = response => {
  try {
    return response.json();
  } catch (err) {
    return { errors: [err.message] };
  }
};

const handleError = err => {
  throw err;
};

const defaultOptions = {
  headers: {
    "Content-Type": "application/json"
  }
};

const Api = {
  exec: (method, url, options = {}) => {
    const fetchOptions = _.merge(
      _.cloneDeep(defaultOptions),
      _.cloneDeep(options),
      { method: method }
    );
    return fetch(url, fetchOptions)
      .then(handleResponse)
      .catch(handleError);
  },
  get: (url, options) => Api.exec("GET", url, options),
  post: (url, payload, options = {}) =>
    Api.exec("POST", url, { ...options, body: JSON.stringify(payload) }),
  patch: (url, payload, options = {}) =>
    Api.exec("PATCH", url, { ...options, body: JSON.stringify(payload) }),
  search: (source, term) => {
    let url;
    switch (source) {
      case "venue":
        return process.env.REACT_APP_ENDPOINT_VENUE;
      case "artist":
        return process.env.REACT_APP_ENDPOINT_ARTIST;
    }
    return Api.get(url.replace(/:term/gi, term));
  },
  saveResponses: (collector, instance, answers) => {
    const url = process.env.REACT_APP_ENDPOINT_RESPONSE;
    const payload = {
      data: {
        collector,
        instance,
        answers
      }
    };
    return Api.post(url, payload);
  },
  getSurvey: (collector, instance) => {
    let url = process.env.REACT_APP_ENDPOINT_SURVEY;
    url += `?c=${collector}`;
    if (instance) {
      url += `&i=${instance}`;
    }
    return Api.get(url);
  },
  geolocate: instanceId => {
    let url = `${process.env.REACT_APP_ENDPOINT_GEOIP}?i=${instanceId ||
      AlphaId.encode()}`;
    return Api.get(url);
  },
  getInstance: id => {
    let url = process.env.REACT_APP_ENDPOINT_INSTANCE;
    return Api.get(`${url}?i=${id}`);
  },
  createInstance: instance => {
    let url = process.env.REACT_APP_ENDPOINT_INSTANCE;
    return Api.post(url, { data: instance });
  },
  updateInstance: id => {
    let url = process.env.REACT_APP_ENDPOINT_INSTANCE;
    return Api.patch(url, { data: { id, status: 10 } });
  },
  getSpotifyUser: token =>
    Api.get("https://api.spotify.com/v1/me", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  createPlaylist: (token, id, name) =>
    Api.post(
      `https://api.spotify.com/v1/users/${id}/playlists`,
      { name: name, public: true },
      { headers: { Authorization: `Bearer ${token}` } }
    ),
  addTracks: (token, id, tracks) =>
    Api.post(
      `https://api.spotify.com/v1/playlists/${id}/tracks`,
      { uris: tracks },
      { headers: { Authorization: `Bearer ${token}` } }
    )
};

export default Api;

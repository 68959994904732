import React, { Fragment, Component } from "react";
import styled from "styled-components";
import _ from "lodash";
import { SurveyContext } from "Providers";
import Layout from "../../layouts/Layout";
import Page from "../../components/Page";
import ThankYou from "../../components/ThankYou";
import { ButtonPrimary, ButtonFacebook, P } from "notes";
import { FormattedMessage } from "react-intl";

const FORWARD = 1;
const BACK = -1;

class Collector extends Layout {
  static contextType = SurveyContext;

  getAuthUrl = () => {
    const parts = window.location.hostname.split(".");
    parts[0] = "auth";
    return `https://${parts.join(".")}${
      [80, 443].indexOf(window.location.port) < 0
        ? `:${window.location.port}`
        : ""
    }/auth/fb`;
  };

  componentDidMount = () => {
    const { current_responses, setState } = this.context;
    const that = this;
    window.addEventListener(
      "message",
      function(e) {
        if (
          !!e.data &&
          typeof e.data === "string" &&
          e.data !== "" &&
          e.data.indexOf("{") > -1
        ) {
          try {
            const data = JSON.parse(e.data);
            // make sure it's an FB message
            if (data.fbauth) {
              that.setState({ fb_login: false });
              const {
                fbauth: { name, email }
              } = data;
              const [firstName, ...lastName] = name.split(" ");
              setState({
                current_responses: {
                  ...current_responses,
                  firstName,
                  lastName: lastName.join(""),
                  email
                }
              });
              that.move(FORWARD, false);
            }
          } catch (err) {
            console.log(err.message);
          }
        }
      },
      false
    );
  };

  render() {
    const { asset, colors } = this.getProperties();

    const {
      state: { current_page, max_page, collector, survey, instance },
      onResponse
    } = this.context;

    if (!collector) return null;

    const {
      id,
      theme,
      setlist: {
        customizations: { socialMediaLogin }
      }
    } = collector;

    // const colors  = _.get(collector, 'colors.user_colors', {})

    if (colors.background) {
      document.body.style.background = colors.background || "#ffffff";
    }

    const status = _.get(instance, "status", 0);
    const { loading } = this.state;

    // Override and go to Thank You if the survey
    // only has a thank you page and no
    // questions
    let gotoThankYou = false;
    try {
      gotoThankYou = survey.Page[current_page].id === "thankyou";
    } catch (err) {}

    return (
      <Fragment>
        <Container>
          <PageContainer>
            {/* {this.header()} */}
            {status === 10 || gotoThankYou ? <ThankYou /> : <Page />}
          </PageContainer>
          {status !== 10 && current_page <= max_page && !gotoThankYou ? (
            <ButtonContainer>
              {current_page > 0 && (
                <ResponsiveButton
                  textColor={theme.button.text}
                  backgroundColor={theme.button.background}
                  floated="left"
                  onClick={() => this.move(BACK)}
                >
                  <FormattedMessage
                    id="button.previous"
                    defaultMessage="Previous"
                  />
                </ResponsiveButton>
              )}
              {current_page <= max_page ? (
                <>
                  {!loading ? (
                    <ResponsiveButton
                      textColor={_.get(theme, "button.text", "initial")}
                      backgroundColor={_.get(
                        theme,
                        "button.background",
                        "initial"
                      )}
                      floated="right"
                      onClick={() => this.move(FORWARD)}
                      data-javelin-name={`submit-survey`}
                    >
                      <FormattedMessage
                        id="button.done"
                        defaultMessage="Done"
                      />
                    </ResponsiveButton>
                  ) : (
                    <ResponsiveButton loading colors={colors} primary>
                      <FormattedMessage
                        id="button.submitting"
                        defaultMessage="Submitting"
                      />
                    </ResponsiveButton>
                  )}
                  {socialMediaLogin && (
                    <>
                      <OrContainer>
                        <FacebookOr>Or</FacebookOr>
                        <OrLine />
                      </OrContainer>
                      <SizedFacebookButton
                        onClick={() => {
                          //this.facebookLogin();
                          // get reference to window in iframe
                          let win = document.getElementById("_fb_login")
                            .contentWindow;
                          // send msg to win
                          win.postMessage("login", "*");
                        }}
                        data-javelin-name={`facebook-login`}
                      >
                        Sign-in with Facebook
                      </SizedFacebookButton>
                    </>
                  )}
                </>
              ) : loading ? (
                <ResponsiveButton loading colors={colors} primary>
                  <FormattedMessage
                    id="button.submitting"
                    defaultMessage="Submitting"
                  />
                </ResponsiveButton>
              ) : (
                <ResponsiveButton onClick={() => this.move(FORWARD)}>
                  <FormattedMessage
                    id="button.submit"
                    defaultMessage="Submit"
                  />
                </ResponsiveButton>
              )}
            </ButtonContainer>
          ) : null}
        </Container>
        <iframe id="_fb_login" height={1} width={1} src={this.getAuthUrl()} />
      </Fragment>
    );
  }
}

export default Collector;

const Container = styled.div`
  width: 100%;
`;

const SizedFacebookButton = styled(ButtonFacebook)`
  width: 100%;
  @media only screen and ${props => props.theme.media.large} {
    width: 220px;
  }
`;

const OrContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OrLine = styled.div`
  height: 1px;
  width: 100%;
  background: ${props => (props.theme.dark ? "#FFF" : "#222")};
  position: absolute;
  top: calc(50% - 1px);
`;
const FacebookOr = styled(P)`
  padding: 14px;
  background: ${props => (props.theme.dark ? "#000000" : "#FAFAFA")};
  z-index: 1;
`;

const ButtonContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 50px 0;
  padding: 0 16px;
  @media only screen and ${props => props.theme.media.large} {
    padding-left: 5%;
    max-width: 400px;
  }
`;

const PageContainer = styled.div`
  margin: 0 auto;
`;

const ResponsiveButton = styled(ButtonPrimary)`
  width: 100%;
  z-index: 2;
  @media only screen and ${props => props.theme.media.large} {
    max-width: 184px;
  }
`;

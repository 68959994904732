import React, { useContext, Fragment } from "react";
import styled from "styled-components";
import _ from "lodash";
import Section from "./Section";
import Spotify from "../Desktop/components/Spotify";
import SongSelections from "../Desktop/components/SongSelections";
import { SurveyContext } from "Providers/SurveyProvider";
import { Header } from "../../Landing/components/Header";
import {
  withDefaultMedia,
  P,
  ErrorMessage,
  H2,
  H3,
  H4,
  GridColumn,
  ButtonPrimary
} from "notes";
import { FormattedMessage } from "react-intl";
import AddToCalendar from "react-add-to-calendar";
import ReactPlayer from "react-player";
import { SongList } from "./SongList";
import "react-add-to-calendar/dist/react-add-to-calendar.min.css";
import { customParse as parse } from "Components";

const Page = ({
  matches,
  errors = null,
  data = null,
  thankYouPage = false
}) => {
  const {
    getCustomization,
    state: {
      survey: { Page, properties },
      collector: {
        setlist: {
          customizations: { calendarInvite }
        }
      },
      formErrors
    },
    skipZip,
    songs
  } = useContext(SurveyContext);

  const getCurrentPage = () => {
    return !!Page ? Page[0] : null;
  };

  const render = () => {
    const page = !!data ? data : getCurrentPage();

    if (!page) return null;

    if (page.id === "thankyou") {
      thankYouPage = true;
    }

    const intro = _.get(page, "intro", null);
    const sections = _.get(page, "Section", []);
    const asset_id = _.get(page, "asset_id", null);
    const asset = !!asset_id
      ? _.find(
          _.get(properties, `data.fillable`, []),
          ({ name }) => name === asset_id
        )
      : null;

    const surveyIntro = getCustomization("surveyIntro");
    const video = getCustomization("thankYouVideo");
    const videoMessage = getCustomization("thankYouVideoMessage");
    const cta = getCustomization("callToAction");

    return (
      <Fragment>
        <Header
          simple={matches.large ? null : thankYouPage ? null : true}
          thankYou={thankYouPage}
        />
        {asset && <img alt="" src={asset.default} style={{ width: "100%" }} />}
        <ContentContainer thankYouPage={thankYouPage ? null : true}>
          {formErrors && formErrors.length ? (
            // Todo: Move Error Message Component to "notes";
            <ErrorMessage
              content={
                <FormattedMessage
                  id="error.form"
                  defaultMessage="Please review the highlighted errors before attempting your submission again."
                />
              }
            />
          ) : null}
          <StyledIntro>
            {!!thankYouPage ? (
              intro
            ) : surveyIntro ? (
              <Fragment>
                {surveyIntro.title && surveyIntro.title !== "" && (
                  <H2>{surveyIntro.title}</H2>
                )}
                {surveyIntro.subTitle && surveyIntro.subTitle !== "" && (
                  <H3>{surveyIntro.subTitle}</H3>
                )}
                {surveyIntro.text && surveyIntro.text !== "" && (
                  <SurveyIntroText>{parse(surveyIntro.text)}</SurveyIntroText>
                )}
              </Fragment>
            ) : (
              <FormattedMessage
                id="message.default_survey_intro"
                defaultMessage="You’re almost done! Just tell us a bit about yourself before submitting your set requests…"
              />
            )}
            {thankYouPage && !!skipZip && (
              <H3 style={{ textAlign: "center" }}>
                Thank you for your donation of ${skipZip}!
              </H3>
            )}
            {thankYouPage && calendarInvite && (
              <CalendarContainer>
                <AddToCalendar
                  event={{
                    location: !!calendarInvite.url
                      ? calendarInvite.url
                      : window.location,
                    ...calendarInvite
                  }}
                  buttonTemplate={{ "calendar-plus-o": "left" }}
                />
              </CalendarContainer>
            )}
          </StyledIntro>
          {thankYouPage && (
            <Fragment>
              {!!video && (
                <VideoContainer xCenter>
                  {!!videoMessage && <H4>{videoMessage}</H4>}
                  {matches.large ? (
                    <ReactPlayer url={video} />
                  ) : (
                    <MobilePlayerContainer>
                      <StyledReactPlayer
                        url={video}
                        width="100%"
                        height="100%"
                      />
                    </MobilePlayerContainer>
                  )}
                </VideoContainer>
              )}
              {!!cta && cta.type && (
                <CTAContainer>
                  {(() => {
                    switch (cta.type) {
                      case "button":
                        return (
                          <CTAButton
                            onClick={() => (window.location = cta.link)}
                          >
                            {cta.label}
                          </CTAButton>
                        );
                        break;
                      default:
                        return null;
                    }
                  })()}
                </CTAContainer>
              )}
            </Fragment>
          )}
          {sections.map((section, index) => {
            switch (section.id) {
              case "songSelections":
                return <SongSelections key={`__song_selections_${index}`} />;
              case "spotify":
                return <Spotify data={section} key={`__spotify_${index}`} />;
              // return null;
              case "social":
                return null; //<Social data={section}/>
              default:
                return <Section key={`__section_${index}`} data={section} />;
            }
          })}
          {thankYouPage && songs && songs.length > 0 && (
            <SongList songs={songs} />
          )}
        </ContentContainer>
      </Fragment>
    );
  };

  return render();
};

export default withDefaultMedia(Page);

const MobilePlayerContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const CTAContainer = styled(GridColumn)`
  margin-top: 40px;
  text-align: center;
  margin-bottom: 30px;
`;

const CTAButton = styled(ButtonPrimary)`
  min-width: 300px;
  margin: 0 auto;
`;

const VideoContainer = styled(GridColumn)``;

const ContentContainer = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0 16px;
  padding-top: 40px;
  width: 100%;
  @media only screen and ${props => props.theme.media.large} {
    padding-left: ${props => (props.thankYouPage ? "5%" : "16px")};
  }
`;

const StyledIntro = styled(P)`
  color: ${props =>
    props.theme.dark ? props.theme.darkDefault : props.theme.lightDefault};
  @media only screen and ${props => props.theme.media.large} {
    color: ${props => props.theme.lightDefault};
  }
`;

const CalendarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 36px;

  .react-add-to-calendar {
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    position: relative;
    display: inline-block;
    margin: 0 auto;

    &__wrapper {
      zoom: 1;
      cursor: pointer;
    }

    background-color: #ffffff;
    /*border: 2px solid ${props => props.theme.colors.action};*/
    color: ${props => props.theme.colors.action};
    padding: 7px 12px;

    /*
    &:hover,
    &:focus {
      background-color: ${props => props.theme.colors.buttonPrimaryBgHover};
      border-color: ${props => props.theme.colors.buttonPrimaryBgHover};
      color: ${props => props.theme.colors.buttonPrimaryText};
    }
*/

    &__button {
      background-color: #ffffff;
      border: 2px solid ${props => props.theme.colors.action};
      color: ${props => props.theme.colors.action};
      padding: 7px 12px;
      &:hover,
      &:focus {
        background-color: ${props => props.theme.colors.buttonPrimaryBgHover};
        border-color: ${props => props.theme.colors.buttonPrimaryBgHover};
        color: ${props => props.theme.colors.buttonPrimaryText};
      }

      &--light {
        background-color: #fff;
        border: 2px solid ${props => props.theme.colors.action};
      }
    }

    &__icon {
      &--right {
        padding-left: 5px;
      }

      &--left {
        padding-right: 5px;
      }
    }

    &__dropdown {
      position: absolute;
      top: 30px;
      left: 1px;
      width: 93%;
      padding: 5px 0 5px 8px;
      box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
      border: 1px solid #a8a8a8;
      background-color: #fff;
      text-align: left;

      ul {
        list-style: none;
        margin: 0;

        li {
          a {
            color: #000;
            text-decoration: none;

            i {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
`;

const SurveyIntroText = styled.div`
  margin-top: 10px;
`;

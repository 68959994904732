import React, { useContext, Fragment, useEffect, useState } from "react";
import _ from "lodash";
import { SurveyContext } from "Providers/SurveyProvider";
import CountryPhone from "./CountryPhone";
import PostalCode from "./PostalCode";
import InputMask from "react-input-mask";
import countries from "modules/countries";
import { validateField, checkValidators } from "modules/validate";
import { Input } from "notes";
import Joi from "@hapi/joi";

const TextInput = ({ data = null, error = false }) => {
  const {
    onResponse,
    getResponse,
    responses,
    setState,
    state: { locale, survey, formErrors }
  } = useContext(SurveyContext);

  const [_value, _dispatch] = useState(_.get(responses(), data.id, null));

  const handleChange = value => {
    _dispatch(value);
    onResponse(data.id, value);
  };

  useEffect(() => {
    if (!data) return;
    //handleChange(null, { value: _.get(responses(), data.id, "") });
    handleChange(_.get(responses(), data.id, ""));
  }, [data]);

  const getPostalCodeMask = () => {
    let mask = "99999";
    try {
      const resp = responses();
      // find a question with a data source of 'country', get the id and
      // then get the value, search the countries object for the matching
      // value and return the zip code.
      const pages = _.get(survey, "Page", []);
      pages.map(page => {
        const sections = _.get(page, "Section", []);
        sections.map(section => {
          const questions = _.get(section, "Question", []);
          const question = _.find(
            questions,
            ({ source }) => source === "country"
          );
          if (question) {
            const response = resp[question.id];
            if (response) {
              const country = _.get(countries, response, null);
              if (country && country === "us") {
                mask = _.get(country, "postalCode", mask);
              }
            }
          }
        });
      });
    } catch (err) {}
    return mask;
  };

  const addError = key => {
    if (!formErrors.includes(key)) {
      const errors = _.clone(formErrors);
      errors.push(key);
      setState({ formErrors: errors });
    }
  };

  const blur = (data, defaultValue) => {
    const value = getResponse(data.id);
    const { error } = validateField(data, value);
    if (error) {
      addError(data.id);
      return;
    }
    // no errors, check validators
    if (
      !checkValidators(
        _.get(data, "validators", []),
        value,
        locale,
        responses()
      )
    ) {
      addError(data.id);
    }
  };

  const display = width => {
    const placeholder = _.get(data, `translations.${locale}.placeholder`, "");

    const validations = _.get(data, "validations", {});

    const validators = _.get(data, "validators", []);

    const className = _.get(data, "className", null);

    let props = {
      placeholder
    };

    if (!!className) {
      props.className = className;
    }

    let inputStyle = {};

    let fieldType = "default";

    let inputType = "text";

    let mask = "**********";

    if (validators.length) {
      validators.map(validator => {
        switch (validator) {
          case "email":
            props.leftIcon = (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 17 16"
                width="16"
                height="16"
              >
                <defs />
                <path
                  fill="#A6AEB2"
                  fill-rule="evenodd"
                  d="M14.448276 2c1.1045695 0 2 .8954305 2 2v8c0 1.1045695-.8954305 2-2 2h-12c-1.1045695 0-2-.8954305-2-2V4c0-1.1045695.8954305-2 2-2h12zm1 3.04l-7 5.6003124-7-5.6003124V12c0 .5128358.38604019.9355072.88337887.9932723L2.448276 13h12c.5128358 0 .9355072-.3860402.9932723-.8833789L15.448276 12V5.04zm-1-2.04h-12c-.47652888 0-.87520798.33331452-.97561418.77952044L8.448276 9.35968758l6.9756142-5.58016714C15.323484 3.33331452 14.9248049 3 14.448276 3z"
                />
              </svg>
            );
            break;
          case "zip":
            props.i = "max-i-location";
            props.iconPosition = "left";
            //fieldType="masked"
            fieldType = "zip";
            inputStyle.maxWidth = "164px";
            //mask = getPostalCodeMask()
            break;
          case "phone":
            props.i = "mobile alternate";
            props.iconPosition = "left";
            fieldType = "phone";
            inputType = "tel"; // For mobile, opens number pad
            break;
          case "age":
            fieldType = "masked";
            inputStyle.maxWidth = "164px";
            mask = "99";
            inputType = "tel"; // For mobile, opens number pad
            if (error) {
              props.i = { name: "warning sign", color: "red", opacity: 1 };
              props.iconPosition = "right";
            }
            break;
          case "year":
            fieldType = "masked";
            inputStyle.maxWidth = "164px";
            mask = "9999";
            inputType = "tel"; // For mobile, opens number pad
            if (error) {
              props.i = { name: "warning sign", color: "red", opacity: 1 };
              props.iconPosition = "right";
            }
            break;
          default:
            break;
        }
      });
    }

    return (
      <div>
        {
          {
            default: (
              <div style={{ position: "relative" }}>
                <Input
                  defaultValue={_value}
                  value={_value}
                  placeholder={placeholder}
                  onChange={handleChange}
                  onBlur={() => (validations.blur ? blur(data) : null)}
                  data-javelin-name={`input-text`}
                  data-javelin-meta={data && data.id}
                  {...props}
                  type={inputType}
                />
                {error && (
                  <i
                    name="warning sign"
                    color="red"
                    style={{ position: "absolute", right: "10px", top: "1rem" }}
                  />
                )}
              </div>
            ),
            phone: (
              <CountryPhone
                defaultValue={_value}
                phoneNumber={_value}
                onChange={handleChange}
              />
            ),
            zip: (
              <div style={{ position: "relative" }}>
                <PostalCode
                  data={_value}
                  onChange={handleChange}
                  onBlur={() => (validations.blur ? blur(data) : null)}
                />
                {error && (
                  <i
                    name="warning sign"
                    color="red"
                    style={{
                      position: "absolute",
                      right: "8rem",
                      top: "1.25rem"
                    }}
                  />
                )}
              </div>
            ),
            masked: (
              <InputMask
                value={_value}
                maskChar=" "
                mask={mask}
                onChange={handleChange}
                onBlur={() => (validations.blur ? blur(data) : null)}
              >
                {() => (
                  <Input
                    defaultValue={_value || ""}
                    {...props}
                    style={{ ...inputStyle }}
                    type={inputType}
                  />
                )}
              </InputMask>
            )
          }[fieldType]
        }
      </div>
    );
  };
  const render = () => {
    return (
      <Fragment>
        {/*
                <Responsive {...Responsive.onlyMobile}>
                    { display(16) }
                </Responsive>
                <Responsive minWidth={768}>
                    { display(8) }
                </Responsive>
                */}
        {display(8)}
      </Fragment>
    );
  };

  return render();
};

export default TextInput;

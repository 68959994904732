import React from "react";
import { FlexBox, H4 } from "notes";
import {
  Header,
  ConnectedSongSelector,
  ConnectedShowSelector,
  ConnectedSongDisplay
} from "./";
import { SurveyConsumer } from "Providers";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

export const DesktopSongs = () => {
  return (
    <SurveyConsumer>
      {({
        state: {
          collector: {
            setlist: { concerts }
          }
        },
        getCustomization
      }) => {
        let label = getCustomization("songSelectorLabel");

        return (
          <>
            <Header />
            {concerts && concerts.length > 0 && (
              <ShowSelectorContainer>
                <ConnectedShowSelector />
              </ShowSelectorContainer>
            )}
            <SongContainer>
              <ListContainer>
                <Subheader>
                  {label ? (
                    label
                  ) : (
                    <FormattedMessage
                      id="label.which_songs"
                      defaultMessage="Which of our songs would you like us to play at your concert?"
                    />
                  )}
                </Subheader>
                <ConnectedSongSelector />
              </ListContainer>
              <ConnectedSongDisplay />
            </SongContainer>
          </>
        );
      }}
    </SurveyConsumer>
  );
};

const Subheader = styled(H4)`
  margin-bottom: 8px;
  @media only screen and ${props => props.theme.media.large} {
    padding-left: 208px;
  }
`;

const ListContainer = styled.div`
  padding-right: 24px;
  max-width: 1016px;
  min-width: 0;
  width: 100%;
`;

const ShowSelectorContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  max-width: 600px;
  padding: 40px 0 81px 5%;
  width: 100%;
`;

const SongContainer = styled(FlexBox)`
  border-top: 1px solid #a6aeb2;
  box-sizing: border-box;
  padding: 55px 16px 95px 16px;
  width: 100%;
`;

import React from "react";
import { SongSelector } from "notes";
import { SurveyConsumer } from "Providers";

export const ConnectedSongSelector = props => (
  <SurveyConsumer>
    {({
      state: {
        collector: { setlist }
      },
      songs,
      setSongs,
      getCustomization
    }) => {
      const image = getCustomization("sideRailImage");
      const link = getCustomization("sideRailImageLink");
      return (
        <SongSelector
          setlist={setlist}
          value={songs}
          onChange={setSongs}
          image={image}
          link={link}
          {...props}
        />
      );
    }}
  </SurveyConsumer>
);
